<template>
  <div class="cookiesComponent">
    <div class="cookies--select row">
      <input
        class="cookies--checkbox"
        type="checkbox"
        name="necessary"
        id="necessary"
        checked
        v-model="necessary"
        @click.prevent
      />
      <label class="cookies noselect" for="necessary">
        <p class="sub-title">Estritamente Necessários</p>
        <p>
          Os cookies essenciais, também conhecidos como cookies "estritamente
          necessários", ativam recursos sem os quais não seria capaz de usar o
          site como pretendido.
        </p>
      </label>
      <input
        class="cookies--checkbox"
        type="checkbox"
        name="performance"
        id="performance"
        v-model="performance"
      />
      <label class="cookies noselect" for="performance">
        <p class="sub-title">Cookies de Desempenho</p>
        <p>
          Estes cookies ajudam-nos a entender como os visitantes usam o nosso
          site, para que seja possível melhorá-lo e testar novas
          funcionalidades.
        </p>
      </label>
      <input
        class="cookies--checkbox"
        type="checkbox"
        name="functional"
        id="functional"
        v-model="functional"
      />
      <label class="cookies noselect" for="functional">
        <p class="sub-title">Cookies Funcionais</p>
        <p>
          Estes cookies ajudam-nos a personalizar a sua visita, lembrando as
          suas preferências e configurações, como páginas que visitou e modelos
          Toyota que explorou.
        </p>
      </label>
    </div>
    <div v-if="saved" class="cookies__modal">
      <p>As suas definições foram gravadas com sucesso!</p>
    </div>
    <ButtonCTA
      btnLabel="Guardar"
      @action="saveCookies()"
      btnClass="primary-btn center-btn half-width-btn "
    />
  </div>
</template>

<script>
import ButtonCTA from "@/components/Buttons/ButtonCTA.vue";

import * as COOKIES from "@/functions/cookies/cookies.js";

export default {
  name: "CookiesSelect",
  components: {
    ButtonCTA,
  },
  data() {
    return {
      cookies: COOKIES.COOKIES_FUNCTIONS.getCookie("_rg_settings"),
      necessary: true,
      performance: false,
      functional: false,
      saved: false,
    };
  },
  created() {
    this.performance = this.cookies
      ? this.cookies.split(",")[1] == "S"
        ? true
        : false
      : false;
    this.functional = this.cookies
      ? this.cookies.split(",")[2] == "S"
        ? true
        : false
      : false;
  },
  methods: {
    saveCookies() {
      let cookies = "";
      cookies += "S,";
      cookies += this.performance ? "S," : "N,";
      cookies += this.functional ? "S" : "N";

      COOKIES.COOKIES_FUNCTIONS.setCookie("_rg_settings", cookies);
      COOKIES.COOKIES_FUNCTIONS.setCookie("_rg_modal", "N");
      this.$store.dispatch("resetState", {
        state: "cookieModal",
        value: COOKIES.COOKIES_FUNCTIONS.getCookie("_rg_modal"),
      });
      this.$store.dispatch("resetState", {
        state: "cookieSettings",
        value: COOKIES.COOKIES_FUNCTIONS.getCookie("_rg_settings"),
      });
      this.showModal();
    },
    showModal() {
      this.saved = true;
      setTimeout(() => {
        this.saved = false;
      }, 2500);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Cookies";
</style>
